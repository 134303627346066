import "./ArticlesText.css";
import React from "react";
import { ArticleContext } from "../../context/ArticleContext";

function ArticlesText(props) {
  const articles = React.useContext(ArticleContext);
  const [refArticle, articleIsVisible] = props.useOnScreen({
    rootMargin: "5px",
  });
  return (
    <section className="article-text" ref={refArticle}>
      {articles.map((art) =>
        `/articles/${art.translit}` === props.location.pathname ? (
          <div
            key={`articletext${art.key}`}
            className="article-text__container"
          >
            <h2 className="section__title">{art.title}</h2>
            <div className="article-text__author-data">
              <div className="article-text__author-container">
                <img
                  className="article-text__author-foto"
                  src={art.foto}
                  alt={art.author}
                />
                <div className="article-text__author-box">
                  <p className="article-text__author">{art.author}</p>
                  <p className="article-text__author-job">{art.job}</p>
                </div>
              </div>
              <p className="article-text__data">{art.date}</p>
            </div>
            <picture>
              <source srcSet={art.webp} type="image/webp" />
              <source srcSet={art.avif} type="image/avif" />
              <source srcSet={art.src} type="image/png" />
              <img
                className="article-text__main-img"
                src={art.src}
                alt={art.title}
                type="image/png"
              />
            </picture>
            <p className="article-text__source">Источник: {art.source}</p>
            {art.text.map((a) =>
              a.type === "text" ? (
                <p className="article-text__text">{a.text}</p>
              ) : a.type === "img" ? (
                <>
                  <picture>
                    <source srcSet={a.webp} type="image/webp" />
                    <source srcSet={a.avif} type="image/avif" />
                    <source srcSet={a.src} type="image/png" />
                    <img
                      className="article-text__main-img"
                      src={a.src}
                      alt={a.source}
                      type="image/png"
                    />
                  </picture>
                  <p className="article-text__source">Источник: {a.source}</p>
                </>
              ) : a.type === "title" ? (
                <h3 className="article-text__title">{a.text}</h3>
              ) : a.type === "list" ? (
                <ul className="article-text__list">
                  {a.text.map((l) => (
                    <li>{l}</li>
                  ))}
                </ul>
              ) : a.type === "reference" ? (
                <p className="article-text__reference">{a.text}</p>
              ) : null
            )}
          </div>
        ) : null
      )}
    </section>
  );
}

export default ArticlesText;
