import React from "react";
import news_img1 from "../images/news/1.jpeg";
import news_img1webp from "../images/news/1.webp";
import news_img1avif from "../images/news/1.avif";
import news_img2 from "../images/news/2.jpeg";
import news_img2webp from "../images/news/2.webp";
import news_img2avif from "../images/news/2.avif";
import news_img3 from "../images/news/3.jpeg";
import news_img3webp from "../images/news/3.webp";
import news_img3avif from "../images/news/3.avif";
import news_img4 from "../images/news/4.jpeg";
import news_img4webp from "../images/news/4.webp";
import news_img4avif from "../images/news/4.avif";
import news_img5 from "../images/news/5.jpeg";
import news_img5webp from "../images/news/5.webp";
import news_img5avif from "../images/news/5.avif";
import news_img6 from "../images/news/6.jpeg";
import news_img6webp from "../images/news/6.webp";
import news_img6avif from "../images/news/6.avif";
import news_img7 from "../images/news/7.jpeg";
import news_img7webp from "../images/news/7.webp";
import news_img7avif from "../images/news/7.avif";
import news_img8 from "../images/news/8.jpeg";
import news_img8webp from "../images/news/8.webp";
import news_img8avif from "../images/news/8.avif";
import news_img9 from "../images/news/9.jpeg";
import news_img9webp from "../images/news/9.webp";
import news_img9avif from "../images/news/9.avif";
import news_img10 from "../images/news/10.jpeg";
import news_img10webp from "../images/news/10.webp";
import news_img10avif from "../images/news/10.avif";
import news_img11 from "../images/news/11.jpeg";
import news_img11webp from "../images/news/11.webp";
import news_img11avif from "../images/news/11.avif";
import news_img12 from "../images/news/12.jpeg";
import news_img12webp from "../images/news/12.webp";
import news_img12avif from "../images/news/12.avif";
import news_img1mini from "../images/news/mini/1.jpeg";
import news_img1miniwebp from "../images/news/mini/1.webp";
import news_img1miniavif from "../images/news/mini/1.avif";
import news_img2mini from "../images/news/mini/2.jpeg";
import news_img2miniwebp from "../images/news/mini/2.webp";
import news_img2miniavif from "../images/news/mini/2.avif";
import news_img3mini from "../images/news/mini/3.jpeg";
import news_img3miniwebp from "../images/news/mini/3.webp";
import news_img3miniavif from "../images/news/mini/3.avif";
import news_img4mini from "../images/news/mini/4.jpeg";
import news_img4miniwebp from "../images/news/mini/4.webp";
import news_img4miniavif from "../images/news/mini/4.avif";
import news_img5mini from "../images/news/mini/5.jpeg";
import news_img5miniwebp from "../images/news/mini/5.webp";
import news_img5miniavif from "../images/news/mini/5.avif";
import news_img6mini from "../images/news/mini/6.jpeg";
import news_img6miniwebp from "../images/news/mini/6.webp";
import news_img6miniavif from "../images/news/mini/6.avif";
import news_img7mini from "../images/news/mini/7.jpeg";
import news_img7miniwebp from "../images/news/mini/7.webp";
import news_img7miniavif from "../images/news/mini/7.avif";
import news_img8mini from "../images/news/mini/8.jpeg";
import news_img8miniwebp from "../images/news/mini/8.webp";
import news_img8miniavif from "../images/news/mini/8.avif";
import news_img9mini from "../images/news/mini/9.jpeg";
import news_img9miniwebp from "../images/news/mini/9.webp";
import news_img9miniavif from "../images/news/mini/9.avif";
import news_img10mini from "../images/news/mini/10.jpeg";
import news_img10miniwebp from "../images/news/mini/10.webp";
import news_img10miniavif from "../images/news/mini/10.avif";
import news_img11mini from "../images/news/mini/11.jpeg";
import news_img11miniwebp from "../images/news/mini/11.webp";
import news_img11miniavif from "../images/news/mini/11.avif";
import news_img12mini from "../images/news/mini/12.jpeg";
import news_img12miniwebp from "../images/news/mini/12.webp";
import news_img12miniavif from "../images/news/mini/12.avif";

import news1 from "../video/news/1.webm";
import news2 from "../video/news/2.webm";
import news3 from "../video/news/3.webm";
import news4 from "../video/news/4.webm";
import news5 from "../video/news/5.webm";
import news6 from "../video/news/6.webm";
import news7 from "../video/news/7.webm";
import news8 from "../video/news/8.webm";
import news9 from "../video/news/9.webm";
import news10 from "../video/news/10.webm";
import news11 from "../video/news/11.webm";
import news12 from "../video/news/12.webm";

import news1_mp4 from "../video/news/1.mp4";
import news2_mp4 from "../video/news/2.mp4";
import news3_mp4 from "../video/news/3.mp4";
import news4_mp4 from "../video/news/4.mp4";
import news5_mp4 from "../video/news/5.mp4";
import news6_mp4 from "../video/news/6.mp4";
import news7_mp4 from "../video/news/7.mp4";
import news8_mp4 from "../video/news/8.mp4";
import news9_mp4 from "../video/news/9.mp4";
import news10_mp4 from "../video/news/10.mp4";
import news11_mp4 from "../video/news/11.mp4";
import news12_mp4 from "../video/news/12.mp4";

export const NewsContext = React.createContext();

export const news = [
  {
    key: 1,
    src: `${news1}`,
    srcmp4: `${news1_mp4}`,
    type: "video",
    title: "HomiTec сегодня",
    img: `${news_img1}`,
    imgwebp: `${news_img1webp}`,
    imgavif: `${news_img1avif}`,
    imgmini: `${news_img1mini}`,
    imgminiwebp: `${news_img1miniwebp}`,
    imgminiavif: `${news_img1miniavif}`,
  },
  {
    key: 2,
    src: `${news2}`,
    srcmp4: `${news2_mp4}`,
    type: "video",
    title: "HomiTec сегодня",
    img: `${news_img2}`,
    imgwebp: `${news_img2webp}`,
    imgavif: `${news_img2avif}`,
    imgmini: `${news_img2mini}`,
    imgminiwebp: `${news_img2miniwebp}`,
    imgminiavif: `${news_img2miniavif}`,
  },
  {
    key: 3,
    src: `${news3}`,
    srcmp4: `${news3_mp4}`,
    type: "video",
    title: "HomiTec сегодня",
    img: `${news_img3}`,
    imgwebp: `${news_img3webp}`,
    imgavif: `${news_img3avif}`,
    imgmini: `${news_img3mini}`,
    imgminiwebp: `${news_img3miniwebp}`,
    imgminiavif: `${news_img3miniavif}`,
  },
  {
    key: 4,
    src: `${news4}`,
    srcmp4: `${news4_mp4}`,
    type: "video",
    title: "HomiTec сегодня",
    img: `${news_img4}`,
    imgwebp: `${news_img4webp}`,
    imgavif: `${news_img4avif}`,
    imgmini: `${news_img4mini}`,
    imgminiwebp: `${news_img4miniwebp}`,
    imgminiavif: `${news_img4miniavif}`,
  },
  {
    key: 5,
    src: `${news5}`,
    srcmp4: `${news5_mp4}`,
    type: "video",
    title: "HomiTec сегодня",
    img: `${news_img5}`,
    imgwebp: `${news_img5webp}`,
    imgavif: `${news_img5avif}`,
    imgmini: `${news_img5mini}`,
    imgminiwebp: `${news_img5miniwebp}`,
    imgminiavif: `${news_img5miniavif}`,
  },
  {
    key: 6,
    src: `${news6}`,
    srcmp4: `${news6_mp4}`,
    type: "video",
    title: "HomiTec сегодня",
    img: `${news_img6}`,
    imgwebp: `${news_img6webp}`,
    imgavif: `${news_img6avif}`,
    imgmini: `${news_img6mini}`,
    imgminiwebp: `${news_img6miniwebp}`,
    imgminiavif: `${news_img6miniavif}`,
  },
  {
    key: 7,
    src: `${news7}`,
    srcmp4: `${news7_mp4}`,
    type: "video",
    title: "HomiTec сегодня",
    img: `${news_img7}`,
    imgwebp: `${news_img7webp}`,
    imgavif: `${news_img7avif}`,
    imgmini: `${news_img7mini}`,
    imgminiwebp: `${news_img7miniwebp}`,
    imgminiavif: `${news_img7miniavif}`,
  },
  {
    key: 8,
    src: `${news8}`,
    srcmp4: `${news8_mp4}`,
    type: "video",
    title: "HomiTec сегодня",
    img: `${news_img8}`,
    imgwebp: `${news_img8webp}`,
    imgavif: `${news_img8avif}`,
    imgmini: `${news_img8mini}`,
    imgminiwebp: `${news_img8miniwebp}`,
    imgminiavif: `${news_img8miniavif}`,
  },
  {
    key: 9,
    src: `${news9}`,
    srcmp4: `${news9_mp4}`,
    type: "video",
    title: "HomiTec сегодня",
    img: `${news_img9}`,
    imgwebp: `${news_img9webp}`,
    imgavif: `${news_img9avif}`,
    imgmini: `${news_img9mini}`,
    imgminiwebp: `${news_img9miniwebp}`,
    imgminiavif: `${news_img9miniavif}`,
  },
  {
    key: 10,
    src: `${news10}`,
    srcmp4: `${news10_mp4}`,
    type: "video",
    title: "HomiTec сегодня",
    img: `${news_img10}`,
    imgwebp: `${news_img10webp}`,
    imgavif: `${news_img10avif}`,
    imgmini: `${news_img10mini}`,
    imgminiwebp: `${news_img10miniwebp}`,
    imgminiavif: `${news_img10miniavif}`,
  },
  {
    key: 11,
    src: `${news11}`,
    srcmp4: `${news11_mp4}`,
    type: "video",
    title: "HomiTec сегодня",
    img: `${news_img11}`,
    imgwebp: `${news_img11webp}`,
    imgavif: `${news_img11avif}`,
    imgmini: `${news_img11mini}`,
    imgminiwebp: `${news_img11miniwebp}`,
    imgminiavif: `${news_img11miniavif}`,
  },
  {
    key: 12,
    src: `${news12}`,
    srcmp4: `${news12_mp4}`,
    type: "video",
    title: "HomiTec сегодня",
    img: `${news_img12}`,
    imgwebp: `${news_img12webp}`,
    imgavif: `${news_img12avif}`,
    imgmini: `${news_img12mini}`,
    imgminiwebp: `${news_img12miniwebp}`,
    imgminiavif: `${news_img12miniavif}`,
  },
];
