import "./Articles.css";
import React from "react";
import { ArticleContext } from "../../context/ArticleContext";

function Article(props) {
  const articles = React.useContext(ArticleContext);
  const [refArticle, articleIsVisible] = props.useOnScreen({
    rootMargin: "5px",
  });
  return (
    <section
      className={
        props.location.pathname === "/" ? "article" : "article article__all"
      }
      ref={refArticle}
    >
      <h2 className="section__title">
        У нас есть статьи основанные на нашем личном опыте
      </h2>
      <div className="article__container">
        {props.location.pathname === "/"
          ? articles.slice(0, 4).map((card) => (
              <div key={`article${card.key}`} className="article__box">
                <picture>
                  <source srcSet={card.webp} type="image/webp" />
                  <source srcSet={card.avif} type="image/avif" />
                  <source srcSet={card.src} type="image/png" />
                  <img
                    className="article__img"
                    src={card.src}
                    alt={card.title}
                    type="image/png"
                  />
                </picture>
                <p className="article__title">{card.title}</p>
                <button
                  className="article__button"
                  onClick={() => props.history(`/articles/${card.translit}`)}
                >
                  Читать дальше
                </button>
              </div>
            ))
          : articles.map((card) => (
              <div key={`article${card.key}`} className="article__box">
                <picture>
                  <source srcSet={card.webp} type="image/webp" />
                  <source srcSet={card.avif} type="image/avif" />
                  <source srcSet={card.src} type="image/png" />
                  <img
                    className="article__img"
                    src={card.src}
                    alt={card.title}
                    type="image/png"
                  />
                </picture>
                <p className="article__title">{card.title}</p>
                <button
                  className="article__button"
                  onClick={() => props.history(`/articles/${card.translit}`)}
                >
                  Читать дальше
                </button>
              </div>
            ))}
      </div>
      {props.location.pathname === "/" ? (
        <button
          className="article__button"
          onClick={() => props.history(`/articles`)}
        >
          Больше статей
        </button>
      ) : null}
    </section>
  );
}

export default Article;
