import React from "react";
import price1 from "../images/price/1.png";
import price1webp from "../images/price/1.webp";
import price1avif from "../images/price/1.avif";
import price2 from "../images/price/2.png";
import price2webp from "../images/price/2.webp";
import price2avif from "../images/price/2.avif";
import price3 from "../images/price/3.png";
import price3webp from "../images/price/3.webp";
import price3avif from "../images/price/3.avif";
import price4 from "../images/price/4.png";
import price4webp from "../images/price/4.webp";
import price4avif from "../images/price/4.avif";

export const PriceContext = React.createContext();

export const prices = [
  {
    key: 1,
    src: `${price1}`,
    webp: `${price1webp}`,
    avif: `${price1avif}`,
    title: "Насосно-смесительная группа с насосом",
    price: "6900 руб.",
  },
  {
    key: 2,
    src: `${price2}`,
    webp: `${price2webp}`,
    avif: `${price2avif}`,
    title:
      'Коллекторная группа 1"х3/4" 2 вых. с расходомерами и термостатическими вентилями',
    price: "9591 руб.",
  },
  {
    key: 3,
    src: `${price3}`,
    webp: `${price3webp}`,
    avif: `${price3avif}`,
    title: 'Кран со сгоном, с термометром 1" (комплект)',
    price: "4980 руб.",
  },
  {
    key: 4,
    src: `${price4}`,
    webp: `${price4webp}`,
    avif: `${price4avif}`,
    title: 'Евроконус для PEX труб 16х3/4"',
    price: "249 руб.",
  },
];
