import React from "react";
import tarif_1 from "../images/tarif/dacha.png";
import tarif_1webp from "../images/tarif/dacha.webp";
import tarif_1avif from "../images/tarif/dacha.avif";
import tarif_2 from "../images/tarif/family.png";
import tarif_2webp from "../images/tarif/family.webp";
import tarif_2avif from "../images/tarif/family.avif";
import tarif_3 from "../images/tarif/smart.png";
import tarif_3webp from "../images/tarif/smart.webp";
import tarif_3avif from "../images/tarif/smart.avif";

export const TarifContext = React.createContext();
export const tarifs = [
  {
    key: 1,
    src: `${tarif_1}`,
    srcWebp: `${tarif_1webp}`,
    srcAvif: `${tarif_1avif}`,
    title: 'Тариф "Дача"',
    price: "3 600 руб/м2",
    subtitle:
      "В тариф включены все оборудование, материалы и работы. Такая система подходит для домов выходного дня и отличается своей простотой и дешевизной, потому что в ней используются материалы из сегмента эконом. Но она надежна за счет открытой прокладки труб и их быстрой ремонтопригодности. Такая система прослужит в среднем 15-20 лет.",
    text: [
      "радиаторное отопление во всем доме. (открытая проводка, полипропилен)",
      "водоснабжение и канализация на 2 санузла. (открытая проводка, полипропилен)",
      "современный электрический или газовый двухконтурный котел. (Stout, Baxi, De Dietrich)",
      "гидравлическая обвязка полипропиленом, арматура латунь",
      "электрический водонагреватель на 100 л. (Эмалированный бак, Thermex, Ariston)",
      "узел ввода холодной воды. (полипропилен, счетчик, фильтр, греющий кабель)",
    ],
  },
  {
    key: 2,
    src: `${tarif_2}`,
    srcWebp: `${tarif_2webp}`,
    srcAvif: `${tarif_2avif}`,
    title: 'Тариф "Семья"',
    price: "5 500 руб/м2",
    subtitle:
      "Данная система отопления и водоснабжения отличается своей надежностью и простотой, за счет использования коллекторной разводки труб и отсутствия соединений в полу и стенах. Такая система прослужит минимум 25 лет.",
    text: [
      "водяной теплый пол на 1 этаже. (полиэтиленовая труба с гарантией 10 лет, без соединений в полу)",
      "радиаторное отопление на 2 этаже (скрытая коллекторная разводка труб, металлопластик или pex европейских производителей)",
      "водоснабжение и канализация на 2 санузла. (скрытая коллекторная разводка труб, металлопластик или pex европейских производителей)",
      "современный электрический или газовый двухконтурный котел. (Stout, Baxi, De Dietrich)",
      "гидравлическая обвязка полипропиленом, арматура латунь",
      "электрический водонагреватель на 100 л. (Эмалированный бак, Thermex, Ariston)",
      "узел ввода холодной воды. (латунные фитинги и арматура, счетчик, фильтр, греющий кабель)",
    ],
  },
  {
    key: 3,
    src: `${tarif_3}`,
    srcWebp: `${tarif_3webp}`,
    srcAvif: `${tarif_3avif}`,
    title: 'Тариф "Смарт"',
    price: "7 450 руб/м2",
    subtitle:
      "Данная система отопления и водоснабжения подходит для домов постоянного проживания с использованием современных технологий. Отличается своей надежностью, простотой и технологичностью. Любой член семьи сможет управлять системой с помощью смартфона. Используется коллекторная разводка труб, что дает высокую надежность за счет отсутствия соединений в полу и стенах. Такая система прослужит минимум 25 лет.",
    text: [
      "водяной теплый пол на 1 этаже. (полиэтиленовая труба с гарантией 10 лет, без соединений в полу)",
      "радиаторное отопление на 2 этаже (скрытая коллекторная разводка труб, металлопластик или pex европейских производителей)",
      "водоснабжение и канализация на 2 санузла. (скрытая коллекторная разводка труб, металлопластик или pex европейских производителей)",
      "рециркуляция ГВС до сантехнических приборов (моментально будете получать горячую воду в кран)",
      "современный электрический или газовый одноконтурный котел. (Stout, Baxi, De Dietrich)",
      "гидравлическая обвязка полипропиленом, арматура латунь",
      "бойлер косвенного нагрева на 150 л. (Эмалированный бак, Stout, Hajdu)",
      "узел ввода холодной воды. (латунные фитинги и арматура, счетчик, фильтр, греющий кабель)",
      "система защиты от протечек",
      "автоматика котельного оборудования Zont, что позволяет управлять отопления с телефона",
    ],
  },
];
