import React from "react";
import prorab from "../images/cooperation/prorab.png";
import stroitel from "../images/cooperation/stroitel.png";
import otdelochnik from "../images/cooperation/otdelochnik.png";
import dizainer from "../images/cooperation/dizainer.png";
import arhitector from "../images/cooperation/arhitector.png";
import proectir from "../images/cooperation/proectir.png";
import cottedj from "../images/cooperation/cottedj.png";
import office from "../images/cooperation/office.png";
import comers from "../images/cooperation/commers.png";
import prorabwebp from "../images/cooperation/prorab.webp";
import stroitelwebp from "../images/cooperation/stroitel.webp";
import otdelochnikwebp from "../images/cooperation/otdelochnik.webp";
import dizainerwebp from "../images/cooperation/dizainer.webp";
import arhitectorwebp from "../images/cooperation/arhitector.webp";
import proectirwebp from "../images/cooperation/proectir.webp";
import cottedjwebp from "../images/cooperation/cottedj.webp";
import officewebp from "../images/cooperation/office.webp";
import comerswebp from "../images/cooperation/commers.webp";
import prorabavif from "../images/cooperation/prorab.avif";
import stroitelavif from "../images/cooperation/stroitel.avif";
import otdelochnikavif from "../images/cooperation/otdelochnik.avif";
import dizaineravif from "../images/cooperation/dizainer.avif";
import arhitectoravif from "../images/cooperation/arhitector.avif";
import proectiravif from "../images/cooperation/proectir.avif";
import cottedjavif from "../images/cooperation/cottedj.avif";
import officeavif from "../images/cooperation/office.avif";
import comersavif from "../images/cooperation/commers.avif";

export const CooperationContext = React.createContext();

export const cooperation = [
  {
    key: "1",
    who: "Прораб",
    src: `${prorab}`,
    webp: `${prorabwebp}`,
    avif: `${prorabavif}`,
    type: "work",
  },
  {
    key: "2",
    who: "Строитель",
    src: `${stroitel}`,
    webp: `${stroitelwebp}`,
    avif: `${stroitelavif}`,
    type: "work",
  },
  {
    key: "3",
    who: "Отделочник",
    src: `${otdelochnik}`,
    webp: `${otdelochnikwebp}`,
    avif: `${otdelochnikavif}`,
    type: "work",
  },
  {
    key: "4",
    who: "Дизайнер",
    src: `${dizainer}`,
    webp: `${dizainerwebp}`,
    avif: `${dizaineravif}`,
    type: "work",
  },
  {
    key: "5",
    who: "Архитектор",
    src: `${arhitector}`,
    webp: `${arhitectorwebp}`,
    avif: `${arhitectoravif}`,
    type: "work",
  },
  {
    key: "6",
    who: "Проектировщик",
    src: `${proectir}`,
    webp: `${proectirwebp}`,
    avif: `${proectiravif}`,
    type: "work",
  },
  {
    key: "1",
    who: "В коттеджах",
    src: `${cottedj}`,
    webp: `${cottedjwebp}`,
    avif: `${cottedjavif}`,
    type: "object",
  },
  {
    key: "2",
    who: "В офисах",
    src: `${office}`,
    webp: `${officewebp}`,
    avif: `${officeavif}`,
    type: "object",
  },
  {
    key: "3",
    who: "На коммерческих объектах",
    src: `${comers}`,
    webp: `${comerswebp}`,
    avif: `${comersavif}`,
    type: "object",
  },
];
