import React from "react";

export const FaqContext = React.createContext();

export const faq = [
  {
    key: "1",
    question:
      "Только начали строить дом, когда обращаться за водяным теплым полом?",
    answer:
      "Даже если строительство дома только планах обратитесь за расчетом отопления. После встречи в шоуруме получите полное понимание по типам теплых полов, радиаторов и инженерных систем Вашего дома. У Вас на руках будет смета со 100% точностью в которой подобраны и учтены все материалы, оборудование и работы.",
    id: 0,
  },
  {
    key: "2",
    question: "Какие сроки монтажа теплого водяного пола?",
    answer:
      "В среднем 80 м2 водяного теплого пола мы монтируем за 1-2 рабочих дня. Делаем пневматические испытания чтобы убедится в герметичности системы. На следующий день заливаем стяжку.",
    id: 1,
  },
  {
    key: "3",
    question: "Какие работы вы еще выполняете?",
    answer:
      "Производим монтаж радиаторов, котельных (на всех видах топлива), водоснабжения, канализации, септиков и станций биологической очистки, а также электрики.",
    id: 2,
  },
  {
    key: "4",
    question: "Какой порядок работы и какие документы я получу?",
    answer:
      "Перед началом монтажа вы вносите остаток (за вычетом аванса 20%) за материал. Привозим материал и выполняем монтаж, формируем акт выполненных работ из которого вычитаем весь оставшийся материал, проверяете и принимаете работы. По итогу у Вас на руках Договор подряда, акт выполненных работ и кассовые чеки. Все документы легко примет налоговая.",
    id: 3,
  },
  {
    key: "5",
    question: "С каким материалом вы работаете?",
    answer:
      "Используем материал и оборудование проверенное лично нами на более чем 500 объектах за 9 лет работы. Покупаем у проверенных поставщиков и бережно храним на наших складах. Выделяем место на складах для оборудования и материала наших клиентов которые выкупают его заранее. За счет постоянного подорожания и высокой инфляции, купив заранее материал заказчики экономят до 150 000 руб. от суммы договора.",
    id: 4,
  },
  {
    key: "6",
    question: "Не хочу приезжать к Вам офис, лучше вы ко мне на объект.",
    answer:
      "За 9 лет работы мы видели более 1000 различных  домов. Мы знаем всю специфику загородного строительства. И это все нам позволяет посчитать смету со 100% точностью не выезжая на объект. В нашем шоуруме, который работает от электрического котла вы увидите все виды теплых полов, радиаторов, котельной автоматики и т.д. Сможете управлять системой с телефона, голосом или по старинке руками. После встречи вы выйдете уже экспертом в сфере отопления и сможете задавать правильные вопросы подрядчикам. На руках у Вас будет точная смета на инженерные системы в Вашем доме.",
    id: 5,
  },
];
