import React from "react";
import article_1 from "../images/article/1.png";
import article_1webp from "../images/article/1.webp";
import article_1avif from "../images/article/1.avif";
import article_2 from "../images/article/2.png";
import article_2webp from "../images/article/2.webp";
import article_2avif from "../images/article/2.avif";
import article_3 from "../images/article/3.png";
import article_3webp from "../images/article/3.webp";
import article_3avif from "../images/article/3.avif";

export const ArticleContext = React.createContext();
export const articles = [
  {
    key: 1,
    translit: "Nazvanie_stati1",
    src: `${article_1}`,
    webp: `${article_1webp}`,
    avif: `${article_1avif}`,
    source: "https://shutterstock.com",
    author: "Иван Иванов",
    foto: `${article_1}`,
    job: "Инженер",
    date: "10.10.2024",
    title:
      "Название статьи название статьи название статьи название статьи название статьи название статьи название статьи",
    text: [
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "list",
        text: [
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
        ],
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "img",
        src: `${article_1}`,
        srcWebp: `${article_1webp}`,
        srcAvif: `${article_1avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "list",
        text: [
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
        ],
      },
      {
        type: "img",
        src: `${article_1}`,
        srcWebp: `${article_1webp}`,
        srcAvif: `${article_1avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "reference",
        text: "Справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка",
      },
    ],
  },
  {
    key: 2,
    translit: "Nazvanie_stati2",
    src: `${article_2}`,
    srcWebp: `${article_2webp}`,
    srcAvif: `${article_2avif}`,
    author: "Иван Иванов",
    foto: `${article_1}`,
    job: "Инженер",
    date: "10.10.2024",
    title:
      "Название статьи название статьи название статьи название статьи название статьи название статьи название статьи",
    text: [
      {
        type: "img",
        src: `${article_2}`,
        srcWebp: `${article_2webp}`,
        srcAvif: `${article_2avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "list",
        text: [
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
        ],
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "img",
        src: `${article_2}`,
        srcWebp: `${article_2webp}`,
        srcAvif: `${article_2avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "list",
        text: [
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
        ],
      },
      {
        type: "img",
        src: `${article_2}`,
        srcWebp: `${article_2webp}`,
        srcAvif: `${article_2avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "reference",
        text: "Справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка",
      },
    ],
  },
  {
    key: 3,
    translit: "Nazvanie_stati3",
    src: `${article_3}`,
    srcWebp: `${article_3webp}`,
    srcAvif: `${article_3avif}`,
    author: "Иван Иванов",
    foto: `${article_1}`,
    job: "Инженер",
    date: "10.10.2024",
    title:
      "Название статьи название статьи название статьи название статьи название статьи название статьи название статьи",
    text: [
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "list",
        text: [
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
        ],
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "list",
        text: [
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
        ],
      },
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "reference",
        text: "Справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка",
      },
    ],
  },
  {
    key: 4,
    translit: "Nazvanie_stati4",
    src: `${article_3}`,
    srcWebp: `${article_3webp}`,
    srcAvif: `${article_3avif}`,
    author: "Иван Иванов",
    foto: `${article_1}`,
    job: "Инженер",
    date: "10.10.2024",
    title:
      "Название статьи название статьи название статьи название статьи название статьи название статьи название статьи",
    text: [
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "list",
        text: [
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
        ],
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "list",
        text: [
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
        ],
      },
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "reference",
        text: "Справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка",
      },
    ],
  },
  {
    key: 5,
    translit: "Nazvanie_stati5",
    src: `${article_3}`,
    srcWebp: `${article_3webp}`,
    srcAvif: `${article_3avif}`,
    author: "Иван Иванов",
    foto: `${article_1}`,
    job: "Инженер",
    date: "10.10.2024",
    title:
      "Название статьи название статьи название статьи название статьи название статьи название статьи название статьи",
    text: [
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "list",
        text: [
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
        ],
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "list",
        text: [
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
        ],
      },
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "reference",
        text: "Справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка",
      },
    ],
  },
  {
    key: 6,
    translit: "Nazvanie_stati6",
    src: `${article_3}`,
    srcWebp: `${article_3webp}`,
    srcAvif: `${article_3avif}`,
    author: "Иван Иванов",
    foto: `${article_1}`,
    job: "Инженер",
    date: "10.10.2024",
    title:
      "Название статьи название статьи название статьи название статьи название статьи название статьи название статьи",
    text: [
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "list",
        text: [
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
        ],
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "list",
        text: [
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
        ],
      },
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "reference",
        text: "Справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка",
      },
    ],
  },
  {
    key: 7,
    translit: "Nazvanie_stati7",
    src: `${article_3}`,
    srcWebp: `${article_3webp}`,
    srcAvif: `${article_3avif}`,
    author: "Иван Иванов",
    foto: `${article_1}`,
    job: "Инженер",
    date: "10.10.2024",
    title:
      "Название статьи название статьи название статьи название статьи название статьи название статьи название статьи",
    text: [
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "list",
        text: [
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
        ],
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "list",
        text: [
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
        ],
      },
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "reference",
        text: "Справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка",
      },
    ],
  },
  {
    key: 8,
    translit: "Nazvanie_stati8",
    src: `${article_3}`,
    srcWebp: `${article_3webp}`,
    srcAvif: `${article_3avif}`,
    author: "Иван Иванов",
    foto: `${article_1}`,
    job: "Инженер",
    date: "10.10.2024",
    title:
      "Название статьи название статьи название статьи название статьи название статьи название статьи название статьи",
    text: [
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "list",
        text: [
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
        ],
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "list",
        text: [
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
          "Список список список список список список список список список список список список список список список",
        ],
      },
      {
        type: "img",
        src: `${article_3}`,
        srcWebp: `${article_3webp}`,
        srcAvif: `${article_3avif}`,
        source: "https://shutterstock.com",
      },
      {
        type: "title",
        text: "Заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок заголовок",
      },
      {
        type: "text",
        text: "Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст",
      },
      {
        type: "reference",
        text: "Справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка справка",
      },
    ],
  },
];
