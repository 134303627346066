import React from "react";
import price2 from "../images/priceotpl/2.png";
import price2webp from "../images/priceotpl/2.webp";
import price2avif from "../images/priceotpl/2.avif";
import price3 from "../images/priceotpl/3.png";
import price3webp from "../images/priceotpl/3.webp";
import price3avif from "../images/priceotpl/3.avif";
import price4 from "../images/priceotpl/4.png";
import price4webp from "../images/priceotpl/4.webp";
import price4avif from "../images/priceotpl/4.avif";
import price5 from "../images/priceotpl/5.png";
import price5webp from "../images/priceotpl/5.webp";
import price5avif from "../images/priceotpl/5.avif";
import price6 from "../images/priceotpl/6.png";
import price6webp from "../images/priceotpl/6.webp";
import price6avif from "../images/priceotpl/6.avif";
import price7 from "../images/priceotpl/7.png";
import price7webp from "../images/priceotpl/7.webp";
import price7avif from "../images/priceotpl/7.avif";
import price8 from "../images/priceotpl/8.png";
import price8webp from "../images/priceotpl/8.webp";
import price8avif from "../images/priceotpl/8.avif";
import price9 from "../images/priceotpl/9.png";
import price9webp from "../images/priceotpl/9.webp";
import price9avif from "../images/priceotpl/9.avif";

export const PriceOtoplenieContext = React.createContext();

export const pricesotopl = [
  {
    key: 1,
    src: `${price5}`,
    webp: `${price5webp}`,
    avif: `${price5avif}`,
    title: "Настенный газовый котел Baxi LUNA3 Comfort 240Fi",
    price: "99060 руб.",
  },
  {
    key: 2,
    src: `${price6}`,
    webp: `${price6webp}`,
    avif: `${price6avif}`,
    title: "Стальной радиатор Buderus VK-Profil тип 21х500х800",
    price: "8280 руб.",
  },
  {
    key: 3,
    src: `${price2}`,
    webp: `${price2webp}`,
    avif: `${price2avif}`,
    title:
      'Коллекторная группа 1"х3/4" 2 вых. с расходомерами и термостатическими вентилями',
    price: "9591 руб.",
  },
  {
    key: 4,
    src: `${price7}`,
    webp: `${price7webp}`,
    avif: `${price7avif}`,
    title: "Труба из полиэтилена PE-RT 16х2.0 с антидиффузионным слоем EVOH",
    price: "87 руб. м.",
  },
  {
    key: 5,
    src: `${price9}`,
    webp: `${price9webp}`,
    avif: `${price9avif}`,
    title: "Электрический котел Stout Plus 14 кВт",
    price: "67922 руб.",
  },
  {
    key: 6,
    src: `${price3}`,
    webp: `${price3webp}`,
    avif: `${price3avif}`,
    title: "Биметаллический радиатор Stout Space 500 10 секций",
    price: "10050 руб.",
  },
  {
    key: 7,
    src: `${price4}`,
    webp: `${price4webp}`,
    avif: `${price4avif}`,
    title:
      "Водонагреватель комбинированного нагрева напольный Stout 150 л., ТЭН 2 кВт.",
    price: "82650 руб.",
  },
  {
    key: 8,
    src: `${price8}`,
    webp: `${price8webp}`,
    avif: `${price8avif}`,
    title: "Труба из сшитого полиэтилена PE-Xa Stout 16х2,0",
    price: "133 руб.м.",
  },
];
