import React from "react";

export const CalculateContext = React.createContext();

export const quiz = [
  {
    question: "№4 Чем планируете отапливать дом? ",
    answer: [
      "Газ",
      "Электричество",
      "Дизель",
      "Дрова, уголь, пеллеты",
      "Тепловой насос",
      "Нужно подобрать оптимальный вариант",
    ],
    key: 1,
    type: "radio",
  },
  {
    question:
      "№5 Что из перечисленного планируется в доме? (можно выбрать один или несколько)",
    answer: [
      "Радиаторы",
      "Водоснабжение",
      "Бойлер косвенного нагрева",
      "Канализация",
      "Станция биологической очистки или септик",
      "Резервный электрический котел",
      "Электрика",
      "Бассейн",
      "Приточно-вытяжная вентиляция",
      "Хамам",
      "Система снеготаяния лестниц, веранды, парковки, дорожек",
    ],
    key: 2,
    type: "checkbox",
  },
  {
    question: "№6 Какая готовность Вашего дома (строительного обьекта)?",
    answer: [
      "Планируется строить",
      "Готов на 30-60% (возведены стены)",
      "Готов на 60-90% (окна, двери)",
      "Готов для внутренних работ",
    ],
    key: 3,
    type: "radio",
  },
];
