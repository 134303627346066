import "./Main.css";
import React from "react";

function Main(props) {
  return (
    <section className="main">
      <div className="main__container">
        <div className="main__box">
          <h1 className={props.classTitle}>{props.title}</h1>
          <p className="main__subtitle">{props.subtitle}</p>
          {props.link}
        </div>
      </div>
    </section>
  );
}

export default Main;
